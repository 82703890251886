/*
 * 업무구분: 활동현황
 * 화 면 명: MSPAP502M
 * 화면설명: 청약서발행내역 조회
 * 접근권한: 지점장
 * 작 성 일: 2022.06.20
 * 작 성 자: 조한결
 */
<template>
  <ur-page-container title="청약서 발행현황" :show-title="true" type="subpage" action-type="search" @action-search-click="fn_Open514Pop">
    <template #frame-header-fixed>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-info-sum" >
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <span class="sum">총 {{lv_MockData.length}}건</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
    </template>
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
    <!-- NoData 영역 ::start  -->
     <ur-box-container v-if="lv_MockData.length === 0" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list no-data">
      <mo-list-item>
        <div class="list-item__contents">
          <div class="list-item__contents__info">
            <span v-if="nodataCd === 1">상세조회 조건을 설정해 주세요.</span>
            <span v-else>데이터가 없습니다.</span>
          </div>
        </div>
      </mo-list-item>
     </ur-box-container>
    <!-- NoData 영역 ::end  -->
        <ur-box-container v-else alignV="start" componentid="ur_box_container_003" direction="column" class="ns-contract-list">
          <mo-list :list-data="lv_MockData" v-if="lv_MockData.length !== 0">
            <template #list-item="{item}">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="name txtSkip">{{item.prdtNm}}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="info_box"></div>
                    <div class="txt_right">
                      <span><strong>{{$commonUtil.numberWithCommas(item.smtotPrm.amount)}}</strong></span>
                    </div>
                  </div>
                  <div class="list-item__contents__info inlineBox">
                    <div class="info_box"></div>
                    <div class="right-box">
                      <span class="txtGray txtSkip mw115">계약자 <b>{{item.contrNm}}</b></span><em>|</em>
                      <span class="txtGray">발행일 {{fn_moment(lv_SearchData.searchDate)}}</span>
                    </div>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="info_box"></div>
                    <div class="right-box">
                      <span class="txtLine" @click="fn_Open511Pop(item.clctCnsltNm, item.clctCnsltNo)">{{item.clctCnsltNm}}({{item.clctCnsltNo}})</span>
                    </div>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MSPAP514P from '@/ui/ap/MSPAP514P'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import MSPAP511P from '@/ui/ap/MSPAP511P'
  import Msg from '@/systems/webkit/msg/msg'
  import moment from 'moment'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP502M",
    screenId: "MSPAP502M",
    components: {
      MSPAP514P
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
      console.log(this.lv_UserInfo)
      console.log('this.lv_UserInfo', this.lv_UserInfo.srvcMngScCd, this.lv_UserInfo.isBMngr)
      this.fn_isDOfRolYnChk()
      this.fn_GetFofRolCallBack()
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPAP502M')
    },
    beforeDestroy() {
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_SearchParam: { whlInqrYn: 'Y', hofOrgNo: '', dofOrgNo: '', fofOrgNo: '', orgNo: '', cnsltInqrYn: '' }, // 검색조건
        lv_IsFirst: true, // 최초조회 여부
        lv_UserInfo: '',
        lv_MockData: [],
        lv_MockDataOrigin: [],
        lv_SearchData: {},
        stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: null}], // NextKey 방식의 페이징에서 다음 페이지의 첫번째 항목의 Key값 저장(http.js에 선언된 변수명과 동일한 이름 사용)
        stndKeyVal: '',
        pageRowCnt: 99999, // 한 페이지에 노출되는 데이터 건수(http.js에 선언된 변수명과 동일한 이름 사용)
        nodataCd: 1, // 1: 데이터가 없습니다. 2: 검색결과가 없습니다
        lv_IsMSPAP502: true,
        lv_AlertPop: {},
        lv_popup514: {},
        lv_popup511: {},
        srchPopupShowYn: false,
        lv_AlertPopup: {},
        lv_FofOrgData: [], // 지점 목록
        isDofRolYn: ''
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    watch: {
      nodataCd () {
        console.log('watch')
        console.log('this.nodataCd', this.nodataCd)
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_Open514Pop
       * 설명       : 상세조회조건 팝업 호출
       * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
       ******************************************************************************/
      fn_Open514Pop() {
        console.log('this.lv_SearchData', Object.keys(this.lv_SearchData).length)
        this.srchPopupShowYn = true // 상세조회조건 활성화시 true
        if (Object.keys(this.lv_SearchData).length === 0) {
          this.lv_popup514 = this.$bottomModal.open(MSPAP514P, {
            properties: {
              title: "상세조회조건",
              pSrnId: 'MSPAP502M',
              userInfo: this.getStore('userInfo').getters.getUserInfo,
              pSearchData: this.lv_SearchData
            },
            listeners: {
              onPopupConfirm: (pData) => {
                console.log("onPopUpClose")
                this.$bottomModal.close(this.lv_popup514)
                this.lv_IsFirst = false
                this.fn_GetData(pData)
                // this.fn_InnerSrch(pData)
                Object.assign(this.lv_SearchData, pData)
                console.log('this.lv_SearchData', this.lv_SearchData)                
              },
              onPopupClose: () => {
                this.$bottomModal.close(this.lv_popup514)
              }
            }
          })
        } else {
          this.lv_popup514 = this.$bottomModal.open(MSPAP514P, {
            properties: {
              title: "상세조회조건",
              pSrnId: 'MSPAP502M',
              userInfo: this.getStore('userInfo').getters.getUserInfo,
              pSearchData: this.lv_SearchData
            },
            listeners: {
              onPopupConfirm: (pData) => {
                console.log("onPopUpClose")
                this.$bottomModal.close(this.lv_popup514)
                this.fn_GetData(pData)
                this.lv_IsFirst = false
                Object.assign(this.lv_SearchData, pData)
                console.log('this.lv_SearchData', this.lv_SearchData)
              },
              onPopupClose: () => {
                this.$bottomModal.close(this.lv_popup514)
              }
            }
          })
        }
      },
      /******************************************************************************
       * Function명 : fn_Open511Pop
       * 설명       : FC문자/전화 팝업 호출
       * 
       ******************************************************************************/
      fn_Open511Pop (cnsltNm, cnsltNo) {
        this.lv_popup511 = this.$bottomModal.open(MSPAP511P, {
          properties: {
            pCnsltNm: cnsltNm,
            pCnsltNo: cnsltNo,
            FCTelBtn: "전화하기",
            FCSMSBtn: "문자하기"
          },
          listeners: {
            onPopupConfirm: () => {
              console.log("onPopupConfirm")
              this.$bottomModal.close(this.lv_popup511)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_popup511)
            }
          }
        })
      },
      /******************************************************************************
      * Function명 : fn_GetData
      * 설명       : data 조회
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_GetData (pData) {
        // if (this.stndKeyVal === 'end') return
        let lv_Vm = this
        console.log('lv_UserInfo.isBMngr', lv_Vm.lv_UserInfo.isBMngr)
          lv_Vm.lv_MockData.slice(0)
          lv_Vm.lv_MockData = []
          lv_Vm.lv_MockDataOrigin.slice(0)
          lv_Vm.lv_MockDataOrigin = []
          lv_Vm.stndKeyList.slice(0)
          lv_Vm.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: null}]
          lv_Vm.stndKeyVal = ''
        if (pData === 'init') {
          let pParams = {
            srchScCd: '2',
            rctmTrtScCd: '9',
            inptYmd: moment(lv_Vm.lv_SearchData).format('YYYYMMDD'),
            ICClctOrgListVO: [], // 사번검색 원할 시 iCClctCnsltListVO: [{clctCnsltNo: this.getStore('userInfo').getters.getUserInfo.userEno.slice(0, 10)}, {clctCnsltNo: '0001057465'}],
            slctYn: 'Y'
          }
          if (this.isDofRolYn  === 'Y') {
            console.log('this.lv_FofOrgData', this.lv_FofOrgData)
            pParams.ICClctOrgListVO.splice(0, pParams.ICClctOrgListVO.length)
            pParams.ICClctOrgListVO = []
              let len = this.lv_FofOrgData.length
              for (let i = 0; i < len; i++) {
                if (this.lv_FofOrgData[i].key !== '0') pParams.ICClctOrgListVO.push({clctOrgNo: this.lv_FofOrgData[i].key})
              }
          } else {
            pParams.ICClctOrgListVO.splice(0, pParams.ICClctOrgListVO.length)
            pParams.ICClctOrgListVO = []
            pParams.ICClctOrgListVO.push({clctOrgNo: lv_Vm.lv_UserInfo.onpstFofOrgNo})
          }

          pParams.pageRowCnt = lv_Vm.pageRowCnt
          pParams.stndKeyList = lv_Vm.stndKeyList
          pParams.fnctScCd = 'S'
          let commHeader = {
            eaiId: 'C341_F1PQL0327_S',
            fahrTrnId: 'S',
            requestSystemCode: 'F1341', // f1092 -> F1341 교체필요
            reqSrvcNm: '',
            reqMthdNm: '',
            targetSystemCode: '',
            resVONm: 'sli.in.ea2.vo.a0025pq.EAF1PQL0327VO',
            reqVONm: 'sli.in.ea2.vo.a0025pq.EAF1PQL0327VO'
          }
          this.$pmUtil.eaiInvoke(pParams, 'txTSSPM01P1', 'S', commHeader, lv_Vm.fn_PMUtilHandler)
        }
        else {
            let pParams = {
            srchScCd: '2', // 조회구분코드 1: 신계약목록 2: 청약서목록
            rctmTrtScCd: '9', // 입금처리구분코드 4: 입금완료 9: 전체
            inptYmd: moment(pData.searchDate).format('YYYYMMDD'),
            ICClctOrgListVO: [], // 사번검색 원할 시 iCClctCnsltListVO: [{clctCnsltNo: this.getStore('userInfo').getters.getUserInfo.userEno.slice(0, 10)}, {clctCnsltNo: '0001057465'}],
            slctYn: 'Y' // 지사전체선택여부 Y: 전체지사선택 N: 지사선택
          }
          if (this.isDofRolYn === 'Y') {
            pParams.ICClctOrgListVO.splice(0, pParams.ICClctOrgListVO.length)
            pParams.ICClctOrgListVO = []
            if (pData.fofOrg.key === '0') {
              let len = pData.FofOrgData.length
              for (let i = 0; i < len; i++) {
                if (pData.FofOrgData[i].key !== '0') pParams.ICClctOrgListVO.push({clctOrgNo: pData.FofOrgData[i].key})
              }
            } else {
              if (pData.fofOrg.key !== '0') pParams.ICClctOrgListVO.push({clctOrgNo: pData.fofOrg.key})
            }
          } else {
            pParams.ICClctOrgListVO.splice(0, pParams.ICClctOrgListVO.length)
            pParams.ICClctOrgListVO = []
            pParams.ICClctOrgListVO.push({clctOrgNo: lv_Vm.lv_UserInfo.onpstFofOrgNo})
          }
          pParams.pageRowCnt = lv_Vm.pageRowCnt
          pParams.stndKeyList = lv_Vm.stndKeyList
          pParams.fnctScCd = 'S'
            let commHeader = {
            eaiId: 'C341_F1PQL0327_S',
            fahrTrnId: 'S',
            requestSystemCode: 'F1341', // f1092 -> F1341 교체필요
            reqSrvcNm: '',
            reqMthdNm: '',
            targetSystemCode: '',
            resVONm: 'sli.in.ea2.vo.a0025pq.EAF1PQL0327VO',
            reqVONm: 'sli.in.ea2.vo.a0025pq.EAF1PQL0327VO'
          }
          console.log('sli.in.ea2.vo.a0025pq.EAF1PQL0327VO', pParams)
          this.$pmUtil.eaiInvoke(pParams, 'txTSSPM01P1', 'S', commHeader, lv_Vm.fn_PMUtilHandler)
        }
      },
      /******************************************************************************
      * Function명 : fn_PMUtilHandler
      * 설명       : PM유틸 핸들러
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_PMUtilHandler (response) {
        if (!response || !response.body) {
          console.log('noting returned')
          this.nodataCd = 2
          return
        }
        // 서버 데이터 가져오기
        if (!_.isEmpty(response) && !_.isEmpty(response.body)) {
          let responseList = response.body.icofrfmPblSpcfListVO
          if (responseList !== null) {
            this.lv_MockData = []
            this.lv_MockDataOrigin = []
            responseList.forEach((item) => {
              this.lv_MockData.push(item)
              this.lv_MockDataOrigin.push(item)
            })
          }
          // 더보기 키값
          this.stndKeyList = []
          if (response.trnstComm.stndKeyList[0]) {
            this.stndKeyList.push(response.trnstComm.stndKeyList[0])
            if (this.stndKeyList === null || this.stndKeyList[0] === null || this.stndKeyList[0].stndKeyVal === null || this.stndKeyList[0].stndKeyVal.trim() === '') {
            // 다음페이지 데이터 존재안함(마지막 페이지)
              this.stndKeyVal = 'end'
            } else {
            // 다음페이지 데이터 존재함
              this.stndKeyVal = 'more'
            }
          }
        }
        else {
          let message = '[' + response.msgComm.msgCd + ']  ' + response.msgComm.msgDesc
          this.fn_BottomAlert(message)
        }
        console.log('fn_PMUtilHandler', response)
        this.fn_InnerSrch(this.lv_SearchData)
      },
      /******************************************************************************
      * Function명 : fn_InnerSrch
      * 설명       : 정렬
      * 인자       : N/A
      * Return     : N/A
      ******************************************************************************/
      fn_InnerSrch (pData) {
        let lv_Vm = this
        console.log('pData', pData)
        if (Object.keys(pData).length === 0) {
          return
        } else {
          this.lv_MockData.splice(0)
          this.lv_MockData = this.lv_MockDataOrigin.slice(0, this.lv_MockDataOrigin.length)
          if (pData.cnslt.key === '0') {
            this.lv_MockData = this.lv_MockDataOrigin.slice(0, this.lv_MockDataOrigin.length)
          } else {
            console.log('selected 컨설턴트 -> 전체아님 filer', this.lv_MockData)
            this.lv_MockData = lv_Vm.lv_MockData.filter(o => o.clctCnsltNo === pData.cnslt.key)
          }
          if (pData.priority.length === 0) return
          this.lv_MockData.sort(function (a, b) {
            if (pData.priority[0].key === '01') { // 높은 순으로.
              if ( a.smtotPrm.intAmount !== b.smtotPrm.intAmount) {
                return Number(a.smtotPrm.intAmount) > Number(b.smtotPrm.intAmount) ? -1 : 1
              }
            } else {
              if ( a.smtotPrm.intAmount !== b.smtotPrm.intAmount) {
                return Number(a.smtotPrm.intAmount) < Number(b.smtotPrm.intAmount) ? -1 : 1
              }
            }
          })
        }
      },
      /******************************************************************************
      * Function명 : fn_moment
      * 설명       : 화면에서 일자 표기
      * 인자       : date
      * Return     : N/A
      ******************************************************************************/
      fn_moment (date) {
        return moment(date).format('YYYY/MM/DD')
      },
      /******************************************************************************
       * Function명 : fn_BottomAlert
       * 설명       : 확인 팝업 호출
       * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
       ******************************************************************************/
      fn_BottomAlert (contents) {
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: '알림',
            content: contents
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.lv_AlertPop)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPop)
            }
          }
        })
      },
      /******************************************************************************
      * Function명 : fn_isDOfRolYnChk
      * 설명       : 로그인 사용자의 시스템 옵션 검색
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_isDOfRolYnChk () {
        let t_isDofRolYn = this.lv_UserInfo.isDofRolYn
        console.log('this.lv_OrgAuth.isDofRolYn', t_isDofRolYn)
        this.isDofRolYn = t_isDofRolYn
      },
      /******************************************************************************
      * Function명 : fn_Init
      * 설명       : 화면 초기화
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_Init () {
        this.lv_MockData.slice(0)
        this.lv_MockData = []
        this.lv_MockDataOrigin.slice(0)
        this.lv_MockDataOrigin = []
        this.stndKeyList.slice(0)
        this.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: null}]
        this.stndKeyVal = ''
      },
      /******************************************************************************
      * Function명 : fn_BackBtnClicked
      * 설명       : 백 버튼 클릭이벤트(Device Back 포함)
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_BackBtnClicked () {
        // 상세조회조건 팝업을 호출한 적이 있으면 뒤로가기 대신 화면을 초기화 한다.
        if (this.srchPopupShowYn) {
          this.lv_SearchData = {}
          this.lv_IsFirst = true
          this.fn_GetData('init')
          this.nodataCd = 1
          this.srchPopupShowYn = false
        } else {
          this.$router.go(-1)
        }
      },
    /******************************************************************************
    * Function명 : fn_RoleBottomAlert
    * 설명       : 단순알림 팝업 호출
    ******************************************************************************/
      fn_RoleBottomAlert () {
        this.lv_AlertPopup = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: '알림',
            content: '대리점을 먼저 선택해 주세요.'
          },
          listeners: {
            onPopupConfirm: () => {
              this.fn_Open514Pop()
              this.$bottomModal.close(this.lv_AlertPopup);
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPopup);
            }
          }
        },
        {properties: {preventTouchClose : false}}
        )},
    /******************************************************************************
    * Function명 : fn_SetHofData
    * 설명       : 시스템 옵션 조회
    * 인자       : BCOptnMngSrchISVO.userEno 사용자사번
    * {isEntpwRolYn[전사역할여부]=Y/N,
    * isHofRolYn[사업부역할여부]=Y/N,
    * isDofRolYn[지역단역할여부]=Y/N,
    * isEofRolYn[지점역할여부]=Y/N}
    * Return     : BCOptnMngSrchOSVO
    ******************************************************************************/
    fn_GetFofRolCallBack ( pData ) {
      this.lv_IsFirst = true
      if ( this.isDofRolYn === 'Y' ) {
        this.lv_SearchParam.fofOrgNo = this.lv_UserInfo.onpstFofOrgNo
        this.lv_SearchParam.orgNo = this.lv_UserInfo.onpstFofOrgNo
        this.$bizUtil.selUserOrgList(this.lv_SearchParam, this.fn_OrgSearchCallBack)
      } else {
        this.fn_GetData('init')
      }
    },
    /******************************************************************************
    * Function명 : fn_LoadingData
    * 설명       : 시스템 옵션 조회
    * 인자       : BCOptnMngSrchISVO.userEno 사용자사번
    * Return     : BCOptnMngSrchOSVO
    ******************************************************************************/
    fn_OrgSearchCallBack ( rtnData, sType ) {
      this.lv_FofOrgData = rtnData[2]
      this.fn_GetData('init')
    },
  },//method end
}
</script>
<style scoped>
</style>